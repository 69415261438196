:root {
  --radiusSmall: 4px;
  --radius: 6px;
  --radiusLarge: 12px;
  --sectionPadding: 96px;
  --componentPadding: 32px;
  --padding: 24px;
  --navPrimary: 200px;
  --navSecondary: 180px;
  --inspector: 400px;
  --inspectorSmall: 400px;
  --inspectorLarge: 400px;
  --navHeight: 56px;
  --navHeightSm: 48px;
  --summaryHeight: var(--navHeight);
  --maxWidth: 980px;
  --breakpoint: 980px;

  --toolbarButtonHeight: 30px;
  --toolbarButtonWidth: 32px;
  --toolbarButtonWidthWide: 42px;

  --iconSm: 16px;
  --iconLg: 28px;
  --iconLgMargin: 12px;
  --overpad: 4px;
  --timelineInset: 85px;

  --box-shadow: 0 10px 20px #0000001a;
  --transition: 0.2s var(--transitionCurve);
  --transitionCurve: cubic-bezier(0.25, 0.46, 0, 0.9);
  --transitionCurveB: cubic-bezier(0.25, 1, 0.5, 1);

  --cursor: pointer;

  --h1: 28px;
  --h2: 25px;
  --h3: 21px;
  --h4: 19px;
  --h5: 16px;
  --h6: 12px;
  --p: 15px;
  --fp: 13.5px;
  --ffp: 12px;
  --lp: 18px;
  --l: 16px;
  --nav: 16px;

  --bga: #ffffffaa;
  --pageLight: #eeeeee;
  --shadow: #00030644;
  --cYellow: #ffa96b99;
  --cCard2: #ffffff;
  --cCard3: #ffffff;
  --fga: rgba(0, 0, 0, 0.05);
  --veil: rgba(0, 0, 0, 0.1);

  --c-menu: #ffffff99;
  --c-menu-light: #ffffff77;
  --c-fg-0: #111012;
  --c-fg-1: #1010128c;
  --c-fg-2: #1010124d;
  --c-border: #0000001c;
  --c-border-light: #00000010;
  --c-bg-0: #ffffff;
  --c-bg-1: #f9f8fa;
  --c-bg-nav: #f9f8fa;
  --c-bg-gray: #f4f3f7;
  --c-bg-inset: #eae7ed;
  --c-cerise-0: #9b1dff;
  --c-cerise-1: #e2bfff;
  --c-cerise-2: #f3e5ff;
  --c-orange-0: #ffad05;
  --c-orange-1: #ffe8c9;
  --c-blue-0: #4503d1;
  --c-blue-1: #e2daff;
  --c-teal-0: #16dfdf;
  --c-teal-1: #e4fbf7;
  --c-lime-0: #bff846;
  --c-lime-1: #dcffc0;
  --c-red-0: #ee4175;
  --c-red-1: #ffd0de;

  --c-good-0: #00ac79;
  --c-good-1: #00ac7933;
  --c-medium-0: #ff9f0f;
  --c-medium-1: #ff9f0f33;
  --c-bad-0: #f91515;
  --c-bad-1: #f9151522;
  --c-done-0: #9b1dff;
  --c-done-1: #9b1dff22;

  --blend: multiply;
  --transparent: #ffffff99;
  --fg: var(--c-fg-0);
  --bg: var(--c-bg-0);
  --cCard: var(--bg);
  --cCard2: var(--bg);
  --cCard3: var(--bg);
  --cAdminAccent: var(--c-cerise-0);
  --cAdminAccentLight: var(--c-cerise-1);
  --cText: var(--fg);
  --cTextSubtle: var(--c-fg-1);
  --cSkeleton: var(--c-border-light);
  --cSkeletonSubtle: var(--c-border-light);
  --cBorder: var(--c-border);
  --cLink: var(--c-cerise-0);
  --cReference: var(--c-cerise-0);
  --cReferenceSubtle: var(--c-cerise-1);
  --cCredit: var(--c-red-0);
  --cCreditLight: var(--c-red-1);
  --cDebit: var(--c-teal-0);
  --cDebitLight: var(--c-teal-1);

  --g-red: linear-gradient(309.59deg, #8111d9 -43.22%, #e93f7a 61.18%, #ee4175 83.73%);
  --g-orange: linear-gradient(318.65deg, #ff3c02 5.27%, #fd7210 34.77%, #ffb300 71.74%);
  --g-blue: linear-gradient(304.98deg, #6e51ff -8.52%, #555ee1 34.14%, #423df9 110.93%);
  --g-lime: linear-gradient(114.97deg, #d7fa2c 11.5%, #bcfa2c 60%, #51de39 129.91%);
  --g-teal: linear-gradient(315.63deg, #2fbdde 11.26%, #1cdddd 58.75%, #38f4f4 84.2%);
  --g-cerise: linear-gradient(307.41deg, #7f09db -1.34%, #9d11d9 57.14%, #cc38ef 94.63%);
  --g-cerise-text: var(--g-cerise);

  --g-cerise-light: linear-gradient(150.89deg, #ffb2ff -12.78%, #f8eeff 47.98%, #dfffff 107.49%);
  --g-orange-light: linear-gradient(
    150.89deg,
    #ffb69d -12.78%,
    #ffe4d4 34.81%,
    #ffe4d4 34.83%,
    #ffeee1 60.51%,
    #fff8bd 107.49%
  );
  --g-teal-light: linear-gradient(150.89deg, #d7ffbf -12.78%, #eefff3 47.98%, #d0ffff 107.49%);
  --g-red-light: linear-gradient(150.89deg, #ffb7ea -12.78%, #ffe9f3 45.47%, #ffe6dd 107.49%);
}
@media (min-width: 1500px) {
  :root {
    --navPrimary: 240px;
    --navSecondary: 200px;
    --inspector: 420px;
    --inspectorSmall: 420px;
    --inspectorLarge: 420px;
  }
}
@media (min-width: 1800px) {
  :root {
    --navPrimary: 240px;
    --navSecondary: 240px;
    --inspector: 520px;
    --inspectorSmall: 520px;
    --inspectorLarge: 520px;
  }
}
@media (max-width: 1024px) {
  :root {
    --navPrimary: 320px;
    --navSecondary: 72px;
    --inspector: 420px;
    --inspectorSmall: 420px;
    --inspectorLarge: 420px;
  }
}
@media (max-width: 728px) {
  :root {
    --navPrimary: 320px;
    --inspector: 90vw;
    --inspectorSmall: 90vw;
    --inspectorLarge: 90vw;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --bga: #07090baa;
    --fga: rgba(255, 255, 255, 0.05);
    --pageLight: #1e2023;
    --cYellow: #5f442e;
    --veil: #07090b55;
    --shadow: var(--bg);
    --blend: screen;
    --filter: invert();

    --c-fg-0: #ffffff;
    --c-fg-1: #ffffff8c;
    --c-fg-2: #ffffff4d;
    --c-menu: #36353899;
    --c-menu-light: #36353855;
    --c-bg-nav: #262527dd;
    --c-border: #ffffff22;
    --c-border-light: #ffffff19;
    --c-bg-0: #000000;
    --c-bg-1: #262527;
    --c-bg-gray: #262527;
    --c-bg-inset: #454347;
    --c-cerise-0: #c37aff;
    --c-cerise-1: #8c00ff77;
    --c-cerise-2: #c57eff33;
    --c-orange-0: #ff8800;
    --c-orange-1: #fd9b133b;
    --c-blue-0: #4503d1;
    --c-blue-1: #4503d199;
    --c-teal-0: #0ceded;
    --c-teal-1: #25e9e93b;
    --c-lime-0: #89ec27;
    --c-lime-1: #99ec473b;
    --c-red-0: #da2f68;
    --c-red-1: #ee417542;

    --transparent: #16161999;
    --fg: var(--c-fg-0);
    --bg: var(--c-bg-0);
    --cCard: var(--bg);
    --cCard2: var(--c-bg-1);
    --cCard3: var(--c-bg-2);
    --cAdminAccent: var(--c-cerise-0);
    --cAdminAccentLight: var(--c-cerise-1);
    --cText: var(--fg);
    --cTextSubtle: var(--c-fg-1);
    --cSkeleton: var(--c-border-light);
    --cSkeletonSubtle: var(--c-border-light);
    --cBorder: var(--c-border);
    --cLink: var(--c-cerise-0);
    --cReference: var(--c-cerise-0);
    --cReferenceSubtle: var(--c-cerise-1);
    --cCredit: var(--c-red-0);
    --cCreditLight: var(--c-red-1);
    --cDebit: var(--c-teal-0);
    --cDebitLight: var(--c-teal-1);

    --g-red: linear-gradient(310.91deg, #8111d9dd -43.22%, #e93f7add 61.18%, #ee4175dd 83.73%);
    --g-orange: linear-gradient(313.45deg, #ff3c02cc 5.27%, #fd7210cc 34.77%, #ffb300cc 71.74%);
    --g-blue: linear-gradient(304.98deg, #6e51ff -8.52%, #555ee1 34.14%, #70adf8 110.93%);
    --g-lime: linear-gradient(110.74deg, #d7fa2caa 11.5%, #bcfa2caa 60%, #51de39aa 129.91%);
    --g-teal: linear-gradient(313.45deg, #2fbddeaa 11.26%, #1cddddaa 58.75%, #38f4f4aa 84.2%);
    --g-cerise: linear-gradient(313.45deg, #bd11d9 0.3%, #8111d9 60.65%, #4511d9 99.74%);
    --g-cerise-text: linear-gradient(313.45deg, #bd11d9 0.3%, #8111d9 60.65%, #4511d9 99.74%);

    --g-cerise-light: linear-gradient(150.89deg, #ffb2ff44 -12.78%, #f8eeff33 47.98%, #dfffff22 107.49%);
    --g-orange-light: linear-gradient(
      150.89deg,
      #ffb69d44 -12.78%,
      #ffe4d433 34.81%,
      #ffe4d433 34.83%,
      #ffeee122 60.51%,
      #fff8bd22 107.49%
    );
    --g-teal-light: linear-gradient(150.89deg, #d7ffbf44 -12.78%, #eefff333 47.98%, #d0ffff22 107.49%);
    --g-red-light: linear-gradient(150.89deg, #ffb7ea44 -12.78%, #ffe9f333 45.47%, #ffe6dd22 107.49%);
  }
}
@keyframes loading {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
