@import 'vars.css';
@import '~antd/dist/antd.css';

*,
input,
select {
  line-height: 1.5;
  /* font-variant-numeric: tabular-nums; */
}

body,
input,
select {
  font-family: 'InterVar', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-feature-settings: 'calt' 1;
}

.sticky {
  position: sticky;
  top: 0;
  background: var(--c-bg-0);
  z-index: 999999;
}

* {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

* {
  outline: none;
  font-variant-ligatures: common-ligatures;
  -webkit-font-variant-ligatures: common-ligatures;
  -moz-font-variant-ligatures: common-ligatures;
  -ms-font-variant-ligatures: common-ligatures;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  text-rendering: optimizelegibility;
  text-size-adjust: none;
  font-optical-sizing: auto;
}

*::-webkit-scrollbar {
  display: none;
}

.signature {
  font-family: 'Shelby', script;
  font-size: 30px;
}

.grow {
  flex-grow: 9;
}

@keyframes pulse {
  from {
    opacity: 0.15;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulseSlow {
  from {
    opacity: 0.35;
  }
  to {
    opacity: 0.5;
  }
}

.pulse {
  animation-fill-mode: forwards;
  animation: pulse 2s infinite alternate;
  will-change: opacity;
  animation-delay: 0.5s;
}

.pulse-slow {
  animation-fill-mode: forwards;
  animation: pulseSlow 1s infinite alternate;
  will-change: opacity;
}

.treeraw * {
  font-family: 'Jetbrains Mono', monospace !important;
  font-size: 12px;
  font-weight: 500;
  background: none !important;
}

.treeraw {
  ul ul ul {
    border-left: 1px solid #00000011;
  }
  li:last-child {
    padding-bottom: 8px !important;
  }
  ul li ul li ul li {
    margin-left: 24px !important;
  }
}

.tree {
  padding: 36px;
  overflow: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tree ul ul ul {
  border-left: 1px solid var(--c-border-light);
}

.tree ul ul ul:hover {
  border-color: var(--c-fg-2);
}

.tree li span {
  white-space: nowrap;
}

.tree li:last-child {
  padding-bottom: 8px !important;
}

.tree ul li ul li ul li {
  margin-left: 32px !important;
}

.theme-dark .tree {
  ul ul ul {
    border-left: 1px solid #ffffff22;
  }
}

.theme-dark .treeraw {
  ul ul ul {
    border-left: 1px solid #ffffff22;
  }
}

.tree * {
}

.ant-popover {
  z-index: 9999999999999;
}

.serif {
  font-weight: 700;
  -moz-font-feature-settings: 'ss01', 'ss04';
  -webkit-font-feature-settings: 'ss01', 'ss04';
  font-feature-settings: 'ss01', 'ss04';
}

.Popover-body {
  /* background: #353d45; */
  padding: 16px 0;
  font-size: 13.5px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  z-index: 999999999999999;
}

#md {
  h1 {
    margin-top: 22px !important;
  }
  pre,
  pre *,
  code,
  code *,
  code span.token {
    font-family: 'Jetbrains Mono', monospace !important;
  }
  pre {
    background-color: #00000009 !important;
    border-radius: 4px;
    padding: 16px !important;
    font-size: 14px;
  }
  h1 {
    font-weight: 700 !important;
  }
  h2 {
    font-weight: 600 !important;
    padding: 24px 0 16px;
  }
  blockquote {
    border-left: 2px solid #00a199;
    margin: 24px 0 !important;
    padding: 8px 24px !important;
    font-weight: 500;
    color: #1f2533;
    * {
      font-size: 18px !important;
    }
    p {
      margin: 0;
    }
  }
}

html {
  height: 100%;
}

background: transparent !important;
height: 100%;

.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
.light {
  font-weight: 400;
}
.thin {
  font-weight: 300;
}
.faded {
  opacity: 0.75;
}
.xfaded {
  opacity: 0.5;
}
.placeholder {
  font-weight: 400 !important;
  opacity: 0.35;
}

.transition {
  transition: 0.15s;
}

button,
button *,
span,
[role='button'] * {
  font-family: 'InterVar', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

h3,
h4,
h5,
h6 {
  font-family: 'InterVar', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 1.5;
}

h1,
h2 {
  line-height: 1.25;
  font-feature-settings: 'ss01';
}

h1 {
  font-size: 30px;
  font-weight: 700;
}
.electron h1 {
  font-size: 24px !important;
}

h2 {
  font-size: 24px;
  font-weight: 500;
}
h3 {
  font-size: 20px;
  font-feature-settings: 'ss01';
}
h4 {
  font-size: 18px;
  * {
    font-feature-settings: 'ss01';
  }
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 500;
}
.caps {
  font-size: 13px;
  text-transform: lowercase;
  font-family: 'Suisse', sans-serif;
  font-variant: small-caps;
  font-feature-settings: none !important;
  letter-spacing: 0;
  font-weight: 500;
  margin: 8px 0 8px;
  opacity: 0.5;
}
p {
  font-size: 15px;
}

p a {
  color: #1365ff;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    color: #3a56a6;
  }
}

.mono,
.mono-sm,
.mono-tt,
.mono *,
.mono-sm *,
.mono-tt * {
  font-feature-settings: 'ss01' 1, 'ss02' 1, 'tnum' 1;
}

.dark-mode #nav.login {
  border-color: transparent !important;
  box-shadow: none !important;
  &:hover {
    background: rgba(255, 255, 255, 0.05) !important;
  }
}

#nav.login {
  transition: none !important;

  border-bottom: none;
  background: transparent !important;
  box-shadow: none !important;
  &:hover {
    background: rgba(0, 0, 0, 0.05) !important;
  }
}

.drop {
  background-color: transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0px !important;
  width: 400px;
  div {
    background-color: transparent;
    border: none;
  }
  .down > div > div {
    background-image: url('https://s.catch.co/admin/down.svg') !important;
    opacity: 0.5;
  }
  select,
  option {
    background-color: transparent;
    padding: 0;
    padding-right: 16px;
    border-radius: 0px !important;
    border: none;
    appearance: none;
    direction: rtl;
  }
}
.reduxInput {
  width: 200px;
  div {
    margin: 0;
    border: none;
    background-size: 12px;
    background-position: right 55%;
    padding: 0;
    right: 0 !important;
    background-color: transparent !important;
  }
  input,
  select {
    border: none;
    background: transparent !important;
    padding: 0;
    text-align: right;

    width: 200px;
    border-radius: 0px !important;
    padding-right: 16px;
  }

  .down > div > div {
    background-image: url('https://s.catch.co/admin/down.svg') !important;
    opacity: 0.5;
  }
  option {
    background: transparent !important;
    border-radius: 0px !important;
    text-align: right;
  }

  input,
  select,
  option {
    font-weight: 500 !important;
  }
}

html,
body {
  height: 100%;
}

body {
  background-color: transparent !important;
  #app {
    background-color: transparent;
  }
  color: black;
  h2,
  div {
    color: black;
  }
}

.row:not(.header) {
  &:hover {
    opacity: 0.8;
  }
}

input {
  width: 100%;
  box-sizing: border-box;
  font-family: 'Inter';
}

.tabLink {
  background-size: 20px auto;
  background-position: 16px center;
  background-repeat: no-repeat;
}
.dark-mode {
  .tabLink.icon-light {
    background-image: url(https://s.catch.co/admin/light.svg);
  }

  .dark-mode .tabLink.icon-light {
    background-image: url(https://s.catch.co/admin/light_fill.svg);
  }

  .tabLink.icon-data {
    background-image: url(https://s.catch.co/admin/data.svg);
  }

  .tabLink.icon-system {
    background-image: url(https://s.catch.co/admin/system.svg);
  }

  .tabLink.icon-people {
    background-image: url(https://s.catch.co/admin/people_fill.svg);
  }

  .tabLink.icon-out {
    background-image: url(https://s.catch.co/admin/out.svg);
  }

  .tabLink.active.icon-data {
    background-image: url(https://s.catch.co/admin/data_fill.svg);
  }

  .tabLink.active.icon-system {
    background-image: url(https://s.catch.co/admin/system_fill.svg);
  }

  .tabLink.active.icon-people {
    background-image: url(https://s.catch.co/admin/people.svg);
  }
}

.light-mode {
  .tabLink.icon-light {
    background-image: url(https://s.catch.co/admin/light_dark.svg);
  }

  .dark-mode .tabLink.icon-light {
    background-image: url(https://s.catch.co/admin/light_fill_dark.svg);
  }

  .tabLink.icon-data {
    background-image: url(https://s.catch.co/admin/data_dark.svg);
  }

  .tabLink.icon-system {
    background-image: url(https://s.catch.co/admin/system_dark.svg);
  }

  .tabLink.icon-people {
    background-image: url(https://s.catch.co/admin/people_fill_dark.svg);
  }

  .tabLink.icon-out {
    background-image: url(https://s.catch.co/admin/out_dark.svg);
  }

  .tabLink.active.icon-data {
    background-image: url(https://s.catch.co/admin/data_fill_dark.svg);
  }

  .tabLink.active.icon-system {
    background-image: url(https://s.catch.co/admin/system_fill_dark.svg);
  }

  .tabLink.active.icon-people {
    background-image: url(https://s.catch.co/admin/people_dark.svg);
  }
}

.primaryInput {
  padding-left: 24px;
}

h1 {
  font-weight: 400;
  opacity: 1;
}

.full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  font-size: 18px;
  font-weight: 400;

  color: var(--c-fg-2);
}

.row {
  flex-direction: row;
  display: flex;
}

.flex-h {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-v {
  display: flex;
  flex-direction: column;
}

.grow {
  flex: 1;
}

.stabilize {
  transform: translateZ(0);
}

a {
  text-decoration: none;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}

.drag {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

#navbar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.no-margin {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Works for firefox */
.hide-scrollbar {
  scrollbar-width: none;
}

@keyframes fadeIn {
  from {
    transform: translateY(0px);
    opacity: 0;
  }
  25% {
    transform: translateY(0px);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInModalContent {
  from {
    opacity: 0;
    transform: scale(0.98) perspective(1px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) perspective(1px) translateZ(0);
  }
}

@keyframes fadeInFullModalContent {
  from {
    opacity: 0;
    transform: scale(0.95) translateX(8px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) perspective(1px) translateZ(0);
  }
}

@keyframes fadeInModal {
  from {
    background: transparent;
  }
  to {
    background: var(--veil);
  }
}

@keyframes fadeInFullSheet {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInModalDark {
  from {
    background: transparent;
  }
  to {
    background: rgba(7, 9, 11, 0.65);
  }
}

@keyframes fadeInTB {
  from {
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes chart {
  from {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes fadeInSubtle {
  0% {
    opacity: 0;
    background: transparent;
  }
  25% {
    opacity: 0;
    background: transparent;
  }
  100% {
    opacity: 0;
    background: transparent;
  }
}

@keyframes fadeInReverse {
  from {
    transform: translateY(-8px);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translateX(24px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(24px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes navIn {
  from {
    opacity: 0;
    transform: translateX(calc(-0.25 * var(--navSecondary)));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInContent {
  animation-duration: 0.25s;
  animation-delay: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInLeft;
}

.navIn {
  animation-duration: 0.25s;
  animation-delay: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: navIn;
}

.fadeInUp {
  animation-duration: 0.25s;
  animation-delay: 0.15s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInUp;
}

.fadeInSubtle {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeIn;
}

.modal {
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInModalContent;

  z-index: 9999999999;

  background: var(--c-menu);
  border-radius: var(--radiusLarge);
  overflow: hidden;
  backdrop-filter: blur(25px) saturate(200%);
  box-shadow: 0 15px 100px 15px var(--shadow), 0 15px 0 -15px var(--shadow);
  border: 0.5px solid var(--c-border);
}

span.b-size {
  font-family: 'New' !important;
  i,
  i::before,
  i *,
  * {
    font-family: 'New' !important;
  }
}
span.b-size.task {
  font-family: 'Tasks' !important;
  i,
  i::before,
  i *,
  * {
    font-family: 'Tasks' !important;
  }
}

.ant-popover-inner {
  box-shadow: var(--box-shadow) !important;
}

.btooltip .ant-popover-inner {
  box-shadow: var(--box-shadow);
  background: black;
}

.popover,
.btooltip {
  padding: 0;
  border-radius: 16px;
  backdrop-filter: blur(25px) saturate(150%);

  z-index: 99999999999999999999999;
}

.theme-dark {
  .popover,
  .btooltip {
    backdrop-filter: blur(25px) saturate(250%);
  }
}

.btooltip {
  border-radius: 12px;
}

.btooltip .ant-popover-inner {
  border-radius: 12px;
  background: var(--c-fg-1);

  .ant-popover-inner-content {
    padding: 8px 0;
  }
}

.ant-popover-inner-content {
  padding: 8px 0;
  max-height: 85vh;
  overflow: scroll;
}

.btooltip .ant-popover-inner * {
  color: var(--c-bg-0);
  font-size: var(--fs-ffp);
}

.popover .ant-popover-inner {
  border-radius: 16px;
  background: var(--c-menu);
  border: 0.5px solid var(--c-border);

  color: var(--fg);

  * {
    color: var(--fg) !important;
  }
}

.btooltip .ant-popover-inner .ant-popover-inner-content {
  padding: 8px 12px;
}

.popover .ant-popover-inner .ant-popover-inner-content {
  padding: 8px 0;
}

.theme-dark .popover .ant-popover-inner {
  background: var(--cCard3);
}

.theme-dark .btooltip .ant-popover-inner {
  background: var(--cCard3);
}

.ant-popover-arrow {
  display: none;
}

.full-modal {
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInFullModalContent;
}

.modal-overlay {
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInModal;
}

.full-overlay {
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0, 0.9);
  animation-name: fadeInTB;
}

.theme-dark .modal-overlay {
  animation-name: fadeInModalDark;
}

.tr-max-width {
  transition: max-width 0.35s cubic-bezier(0.25, 0.46, 0, 0.9);
}

input {
  font-family: 'InterVar', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.k-positioner {
  background: var(--veil) !important;
}

.k-input {
  font-family: 'InterVar', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: var(--c-fg-0);
  height: 64px;
  font-weight: 400;
  font-size: 18px !important;
  border-bottom: 1px solid var(--c-border-light) !important;
  background: transparent !important;
}

.k-animator {
  background: var(--c-menu);
  border-radius: var(--radiusLarge);
  overflow: hidden;
  backdrop-filter: blur(25px) saturate(200%);
  box-shadow: 0 15px 100px 15px var(--shadow), 0 15px 0 -15px var(--shadow);
  border: 0.5px solid var(--c-border);
}

.k-focused {
  background: var(--fga);
  font-size: var(--ffp);
  border-radius: var(--radius);
  padding: 6px 8px;
  line-height: 1;
  color: var(--c-fg-2);
  font-weight: 450;
  display: inline-block;
  flex: 0;
  align-self: flex-start;
  margin: 12px 16px 0;
}

.k-positioner {
  background: none;
  width: 600px;
}

.text-field input {
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 12px;
  font-size: var(--h4);
  border: 1.5px solid transparent;
  font-weight: 400 !important;
  background: var(--c-bg-gray);
}
.text-field input:focus {
  border-color: var(--fg) !important;
}

.ant-popover-open {
  background: var(--c-border-light) !important;
}
@media (prefers-color-scheme: dark) {
  .ant-popover-open {
    background: var(--c-border) !important;
    color: var(--c-fg-1) !important;
  }
}

.mobile-only {
  display: none;
}

@media (max-width: 1024px) {
  .mobile-only {
    display: block;
  }
  .no-mobile {
    display: none !important;
  }
}

#tooltip-root {
  z-index: 9999999999999;
}

.TooltipContent {
  animation-duration: 200ms;
  animation-timing-function: var(--transitionCurve);
  will-change: transform, opacity;
}

.TooltipContent[data-state='delayed-open'] {
  animation-name: scaleInTT;
}

@keyframes scaleInTT {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
